* { box-sizing: border-box; }

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  font: 1.6rem/1.4 'Montserrat', sans-serif;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  color: map_get($grayscale, 'darker');

  a {
    color: $chathams-blue;

    &:hover {
      text-decoration: none;
    }
  }
}
