.hero {
  background-color: #ddd;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  height: 24rem;
  left: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  position: relative;
  right: 50%;
  width: 100vw;
}
