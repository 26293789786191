html {
  @include mq($until: medium) {
    scroll-padding-top: 10rem;
  }

  scroll-padding-top: 13rem;
}

body > div {
  padding-top: 9rem;

  @include mq($until: medium) {
    padding-top: 6rem;
    scroll-padding-top: 6em;
  }
}

header {
  @extend %list-flex;
  @extend %list-less;

  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
  display: flex;
  font-size: 1.6rem;
  padding: 1rem 0;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 10;

  a {
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  .logo {
    display: block;
    line-height: 0;

    img {
      width: 15rem;
      height: auto;

      @include mq($until: medium) {
        width: 9.5rem;
      }
    }
  }

  nav {
    display: flex;
    flex: 1 1 100%;
    justify-content: flex-end;

    ul {
      justify-content: flex-end;
    }

    li + li {
      margin-left: 4rem;
    }

    a {
      align-items: center;
      display: flex;
    }

    .burger-menu {
      display: none;
    }

  }


  .current {
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }

  @include mq($until: medium) {
    $bar-transition: all .2s;
    max-height: 6rem;
    padding: 1rem;

    %bar {
      background: $chathams-blue;
      display: block;
      content: '';
      height: .4rem;
      transition: $bar-transition;
      width: 2.4rem;
    }

    nav {
      height: 100%;
      padding-top: 6.4rem;
      pointer-events: none;
      position: fixed;
      right: 0;
      top: 0;
      width: 80%;

      ul {
        border-top: .1rem dashed map_get($grayscale, 'light');
        display: block;
        pointer-events: all;
        transform: translateX(100%);
        transition: $bar-transition;
        width: 100%;

        li {
          border-bottom: .1rem dashed map_get($grayscale, 'light');
          display: block;
          padding: 1.6rem 1rem;

          + li {
            margin-left: 0;
          }
        }
      }

      .burger-menu {
        background: transparent;
        border: 0 none;
        cursor: pointer;
        display: block;
        margin: 0;
        overflow: hidden;
        padding: .6rem;
        pointer-events: all;
        position: absolute;
        right: 2rem;
        top: 1.3rem;

        span {
          margin: .4rem 0;
          @extend %bar;
        }

        &:before,
        &:after {
          content: '';
          @extend %bar;
        }
      }

      &.expanded {
        background-color: $white;
        border-left: .3rem solid $chathams-blue;
        padding: 6.4rem 1.6rem;

        .burger-menu {
          height: 3.6rem;

          span{
            background: transparent;
          }
          &:before{
            transform: rotate(45deg);
          }

          &:after{
            transform: rotate(-45deg);
            margin-top: -1.6rem;
          }
        }

        ul {
          transform: translateX(0);
        }

        a {
          color: map_get($grayscale, 'darker');
        }

        .current {
          a {
            color: $chathams-blue;
            text-decoration: none;
          }
        }
      }
    }
  }
}

footer {
  background: map_get($grayscale, 'lighter');
  display: flex;
  font-size: 1.2rem;
  padding: 1.5rem 0 1.9rem;

  @extend %list-flex;
  @extend %list-less;

  ul {
    li + li {
      margin-left: 1rem;
      padding-left: 1.3rem;
      position: relative;

      &:before {
        content: '-';
        display: block;
        left: 0rem;
        position: absolute;
      }
    }
  }

  .copy {
    flex: 1 1 auto;
  }

  a {
    color: inherit;
  }
}

.content {
  margin-bottom: 4rem;
}

.wrapper {
  display: inherit;
  flex: 1 1 100%;
  margin: 0 auto;
  max-width: 96rem;

  @include mq($until: large) {
    padding: 0 2rem;
  }
}
