.btn, button {
  appearance: none;
  background-color: $chathams-blue;
  border: 0;
  color: $white;
  display: inline-block;
  font-family: inherit;
  padding: 1.6rem 2rem;
  text-decoration: none;
}
