h1, h2, h3, h4 {
  font-family: Montserrat, sans-serif;
  color: $chathams-blue;
  font-weight: normal;
}

h1 {
  font-size: 4rem;
  font-weight: 300;
  margin: 4rem 0;
}

h2 {
  font-size: 3.2rem;
  margin: 4rem 0;
}

h3 {
  font-size: 2.8rem;
  margin: 4rem 0;
}

