/** =================== MONTSERRAT =================== **/

/** Montserrat Regular **/
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 400;
    font-style: normal;
    src: url("Montserrat-Regular.woff2") format("woff2"),
         url("Montserrat-Regular.woff") format("woff");
}

/** Montserrat Regular-Italic **/
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 400;
    font-style: italic;
    src: url("Montserrat-Italic.woff2") format("woff2"),
         url("Montserrat-Italic.woff") format("woff");
}

/** Montserrat Medium **/
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 500;
    font-style: normal;
    src: url("Montserrat-Medium.woff2") format("woff2"),
         url("Montserrat-Medium.woff") format("woff");
}

/** Montserrat Medium-Italic **/
@font-face {
    font-display: swap;
    font-family: "Montserrat";
    font-weight: 500;
    font-style: italic;
    src: url("Montserrat-MediumItalic.woff2") format("woff2"),
         url("Montserrat-MediumItalic.woff") format("woff");
}
