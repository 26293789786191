%list-less {
  ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

%list-flex {
  ul, ol {
    &,
    & > li {
      display: flex;
    }
  }
}
